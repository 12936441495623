import { config as appConfig } from '@hp/config';
import { DynamicConfigRoot } from '@hp/core/shared';
import { QueryParams } from '@hp/seo';
import { cookies, ErrorCode, getAllFeatures } from '@hp/utils';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import fetch from 'cross-fetch';
import firebase from 'firebase/app';
import { NextPageContext } from 'next';

type ApolloInitProps = {
  relativeUrl: string;
  ctx?: NextPageContext;
  initialState?: NormalizedCacheObject;
  dynCfg: DynamicConfigRoot & { name: string };
};

let apolloClient = null;

const oAuthLink = (_ctx: NextPageContext) =>
  //@ts-ignore
  setContext(async ({ operationName }, { headers }) => {
    const user = firebase.auth().currentUser || null;
    const jwtToken = user ? await user.getIdToken() : null;
    return {
      headers: {
        ...headers,
        authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    };
  });

const getCurrentFeaturesHeaders = (features: DynamicConfigRoot['features']) =>
  Object.entries(getAllFeatures(features))
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('; ');

const httpLink = (
  relativePath: string,
  dynCfg: DynamicConfigRoot & { name: string },
): HttpLink => {
  if (typeof window === 'undefined') {
    return new HttpLink({
      uri: `${appConfig.api.internalServerBase}${relativePath}`,
      credentials: 'same-origin',
      headers: {
        Features: getCurrentFeaturesHeaders(dynCfg.features),
        dynCfgName: dynCfg.name,
        dynCfgVersion: dynCfg.version,
      },
    });
  } else {
    return new HttpLink({
      uri: relativePath,
      credentials: 'same-origin',
      headers: {
        Features: getCurrentFeaturesHeaders(dynCfg.features),
        dynCfgName: dynCfg.name,
        dynCfgVersion: dynCfg.version,
      },
    });
  }
};

const errorLink = (ctx: NextPageContext) =>
  onError(({ graphQLErrors, networkError /*, operation*/ }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions?.ERROR_CODE === ErrorCode.UNAUTHORIZED) {
          cookies.clearAuthCookies(ctx); //todo: maybe is is not required nowadays
          // operation.getContext().cache.writeQuery({
          //   query: LocalIsAuthedDocument,
          //   data: { local: { isAuthed: false } },
          // });

          if (typeof window !== 'undefined') {
            window.location.replace(
              `/login?${QueryParams.redirectTo}=${encodeURIComponent(
                window.location.pathname,
              )}`,
            );
          }
        }

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

const create = ({
  relativeUrl,
  ctx,
  initialState,
  dynCfg,
}: ApolloInitProps): ApolloClient<NormalizedCacheObject> => {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient

  return new ApolloClient({
    connectToDevTools: true,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      oAuthLink(ctx),
      errorLink(ctx),
      httpLink(relativeUrl, dynCfg),
    ]),
    cache: new InMemoryCache({
      addTypename: false,
      freezeResults: true,
    }).restore(initialState || {}),
  });
};

export default function initApollo(
  props: ApolloInitProps,
): ApolloClient<NormalizedCacheObject> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(props);
  }
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(props);
  }

  return apolloClient;
}
