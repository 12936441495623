import { getDataFromTree } from '@apollo/react-ssr';
import { config } from '@hp/config';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { AppContextType } from 'next/dist/next-server/lib/utils';
import Head from 'next/head';
import React, { Component } from 'react';

import initApollo from './initApollo';

// Gets the display name of a JSX component for dev tools
const getComponentDisplayName = (Component) => {
  return Component.displayName || Component.name || 'Unknown';
};

type Props = {
  graphServerState: {
    apollo: any;
  };
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

const withApollo = (ComposedComponent) => {
  return class WithGraphData extends Component<Props> {
    static displayName = `WithGraphData(${getComponentDisplayName(
      ComposedComponent,
    )})`;

    apollo: any;

    static async getInitialProps(appContext: AppContextType) {
      // Initial serverState with apollo (empty)
      let graphServerState = {
        apollo: {
          data: {},
        },
      };
      const apollo = initApollo({
        relativeUrl: config.api.graphqlLocalEndpoint,
        ctx: appContext.ctx,
        //@ts-ignore
        dynCfg: appContext?.ctx?.res?.locals?.dynCfg,
      });

      // Evaluate the composed component's getInitialProps()
      let composedInitialProps = {};
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps({
          ...appContext,
          ctx: { ...appContext.ctx, apolloClient: apollo },
        });
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      if (!process.browser) {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <ComposedComponent
              {...composedInitialProps}
              Component={appContext.Component}
              router={appContext.router}
              apolloClient={apollo}
            />,
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          console.error(error);
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
        }
        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();

        // Extract query data from the Apollo store
        graphServerState = {
          apollo: {
            data: apollo.cache.extract(),
          },
        };
      }

      return {
        graphServerState,
        ...composedInitialProps,
      };
    }

    constructor(props) {
      super(props);
      this.apollo = initApollo({
        relativeUrl: config.api.graphqlLocalEndpoint,
        initialState: this.props.graphServerState.apollo.data,
        dynCfg: props.dynCfg,
      });
    }

    render() {
      return <ComposedComponent {...this.props} apolloClient={this.apollo} />;
    }
  };
};

export default withApollo;
