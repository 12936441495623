import { ApolloProvider } from '@apollo/react-common';
import { Loading } from '@hp/atomic';
import { config, DyncCfgProvider, Language } from '@hp/config';
import { ProgressBarProvider, useUserContext, withContext } from '@hp/context';
import { DynamicConfigRoot } from '@hp/core/shared';
import { loadCatalog } from '@hp/locale';
import {
  useStoreAccountSource,
  useUserLoginEvent,
  useUserLogoutEvent,
  useVerifyEmailId,
  VerifyEmailQuery,
} from '@hp/order';
import {
  RouteProps,
  routes,
  RoutesType,
  useHistory,
  useRouter,
  useUtmSource,
  UtmQuery,
} from '@hp/seo';
import { GlobalStyles } from '@hp/theme';
import {
  changeDataLayerItem,
  cookies,
  GTMPageView,
  StorageKeys,
} from '@hp/utils';
import { Catalog } from '@lingui/core';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import App, { AppProps } from 'next/app';
import { AppContextType } from 'next/dist/next-server/lib/utils';
import Head from 'next/head';
import { Router } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Normalize } from 'styled-normalize';

import { isBrowserFeasible } from '../lib/isBrowserFeasible';
import withApollo from '../lib/withApollo';
import { LanguageProvider } from '../providers/LanguageProvider';
import LinguiProvider from '../providers/LinguiProvider';
import { SystemModalProvider } from '../providers/SystemModal';

type Props = {
  language: Language;
  catalog: Catalog;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  authorized: boolean;
  catalogFullLoaded: boolean;
  query: UtmQuery & VerifyEmailQuery;
  dynCfg: DynamicConfigRoot & { name: string };
} & AppProps;

declare global {
  interface Window {
    dpdDynCfg: DynamicConfigRoot & { name: string };
    dpdAppVersion: string;
    dpdEnv: string;
    /* subset of dpdDynCfg, but it is available just from the beginning, see _document.tsx */
    dpdFeatures: DynamicConfigRoot['features'];
    dpdServerTime: number;
    dpdTzOffsetDelta: number;
  }
}

const hasAuth = (routeProps?: RouteProps) => {
  if (!routeProps) return false;
  if (typeof routeProps.auth === 'function') return routeProps.auth();
  return routeProps?.auth;
};

const MyApp = ({
  Component,
  pageProps,
  language: languageProps,
  catalog: catalogProps,
  catalogFullLoaded: catalogFullLoadedProps,
  apolloClient,
  router,
  query,
  dynCfg,
}: Props) => {
  const nextRouter = useRouter();

  const isVerifyEmailBackLink =
    router.pathname === '/login' && !!router.query?.verified;

  const isCurrentRoute = ({ href }: RouteProps) => {
    if (typeof href === 'function') {
      const finalHref = href(router.query);
      return finalHref === router.asPath;
    }
    return href === router.route;
  };

  const currentRouteEntry = (isVerifyEmailBackLink
    ? ['login', routes['login']]
    : Object.entries(routes).find(([_, value]) => isCurrentRoute(value))) as [
    keyof RoutesType | undefined,
    RouteProps | undefined,
  ];

  const currentRoute = currentRouteEntry?.[0];
  const currentRouteProps = currentRouteEntry?.[1];
  const { requirements, ...restRouteProps } = currentRouteProps ?? {};

  useEffect(() => {
    const scrollToTop = () => window.scrollTo(0, 0);
    Router.events.on('routeChangeComplete', scrollToTop);

    return () => {
      Router.events.off('routeChangeComplete', scrollToTop);
    };
  });

  // Initiate GTM
  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const [data, setData] = useState({
    catalog: catalogProps,
    catalogFullLoaded: catalogFullLoadedProps,
    language: languageProps,
    canRenderPage: !currentRouteProps?.noSsr && !hasAuth(currentRouteProps),
  });

  const history = useHistory();
  useUtmSource(query);
  useStoreAccountSource(apolloClient, currentRoute, router.asPath);
  useUserLoginEvent(currentRoute);
  useUserLogoutEvent(apolloClient);
  useVerifyEmailId(apolloClient);

  const { language, catalogFullLoaded, catalog, canRenderPage } = data;
  const ssr = typeof window === 'undefined';

  useEffect(() => {
    //remember for case when page is not rendered on the client
    if (!window.dpdDynCfg && dynCfg) {
      window.dpdDynCfg = dynCfg;
    }
  }, []);

  const { loading: userIsLoading, user } = useUserContext();

  useEffect(() => {
    changeDataLayerItem(
      { user_type: user ? 'logged-in-user' : 'visitor' },
      'user_type',
    );
  }, [user]);

  const isUserStuffRequired = !ssr && hasAuth(currentRouteProps);

  const fallbackRoute =
    (!ssr || (ssr && !currentRouteProps?.noSsr)) &&
    (!isUserStuffRequired || (isUserStuffRequired && !userIsLoading)) &&
    requirements?.({
      language,
      ssr,
      user,
      //@ts-ignore  if requirements exists, then restRouteProps is not "{}""
      routeProps: restRouteProps,
      query: router.query,
      history: history.history,
      currentRoute,
    });
  if (fallbackRoute && !ssr) {
    nextRouter.push(fallbackRoute);
  }

  const changeLanguage = (lang: Language) => {
    setData((prev) => ({
      ...prev,
      catalogFullLoaded: false,
      language: lang,
    }));

    cookies.set(null, StorageKeys.language, lang);
  };

  useEffect(() => {
    if (canRenderPage && catalogFullLoaded) return;
    if (isUserStuffRequired) {
      if (userIsLoading) {
        //waiting until user is loaded //todo: Load language by user's profil
        if (!user) return;
      } else {
        if (!catalogFullLoaded || !!user) {
          //catalog was not loaded, loading...
          loadCatalog(language).then((newCatalog) => {
            setData((prev) => ({
              ...prev,
              catalog: newCatalog,
              catalogFullLoaded: true,
              canRenderPage: true,
            }));
          });
        } else {
          //catalog is not required
          setData((prev) => ({
            ...prev,
            canRenderPage: true,
          }));
        }
      }
    } else {
      //user is not required, load catalog if necessary
      if (!catalogFullLoaded) {
        loadCatalog(language).then((newCatalog) => {
          setData((prev) => ({
            ...prev,
            catalog: newCatalog,
            catalogFullLoaded: true,
            canRenderPage: true,
          }));
        });
      }
    }
  }, [
    language,
    catalogFullLoaded,
    isUserStuffRequired,
    user,
    userIsLoading,
    canRenderPage,
  ]);

  useEffect(() => {
    if (!canRenderPage || !currentRoute) return;
    history.push(currentRoute);
  }, [canRenderPage, currentRoute]);
  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no"
          name="viewport"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {canRenderPage && !fallbackRoute ? (
        <DyncCfgProvider config={dynCfg}>
          <LanguageProvider language={language} setLanguage={changeLanguage}>
            <ApolloProvider client={apolloClient}>
              <LinguiProvider
                catalogs={{ [language]: catalog }}
                language={language}
                loading={!catalogFullLoaded}
              >
                <Normalize />
                <GlobalStyles />
                <SystemModalProvider>
                  <ProgressBarProvider
                    route={currentRoute}
                    bypass={!currentRouteProps?.noSsr}
                  >
                    <Component {...pageProps} />
                  </ProgressBarProvider>
                </SystemModalProvider>
              </LinguiProvider>
            </ApolloProvider>
          </LanguageProvider>
        </DyncCfgProvider>
      ) : (
        <Loading />
      )}
    </>
  );
};

const filterNonSerializedMessages = (messages: Catalog['messages']) => {
  const result = {};
  const serializedEntries = Object.entries(messages).filter(
    ([_key, value]) => typeof value === 'string',
  );
  serializedEntries.forEach(([key, value]) => (result[key] = value));
  return result;
};

MyApp.getInitialProps = async (appContext: AppContextType<Router>) => {
  const appProps = await App.getInitialProps(appContext);
  const { req, res, query } = appContext.ctx;

  const ssr = typeof window === 'undefined';
  //@ts-ignore
  const dynCfg = ssr ? res.locals.dynCfg : window.dpdDynCfg;
  const language =
    cookies.get(appContext.ctx, StorageKeys.language) ||
    config.app.defaultLanguage;

  if (ssr && !isBrowserFeasible(req.headers['user-agent'])) {
    if (!req.url.includes('browser-not-supported.html')) {
      //@ts-ignore
      res.redirect(`/browser-not-supported.html`);
      res.end();
      return {};
    }
  }

  let catalog: Catalog = await loadCatalog(language);
  if (ssr) {
    //when SSR, catalog does not persist all props (e.g. function can' be serailized) ;
    //we use all, what we can, for example for proper rendering headers
    catalog = {
      messages: filterNonSerializedMessages(catalog.messages),
    };
  }

  return {
    ...appProps,
    dynCfg,
    query,
    language,
    catalog,
    catalogFullLoaded: !ssr,
  };
};

export default withContext(withApollo(MyApp));
